export const seo = {
  url: 'firma/wspieramy/omida-bulldogs',
  title: {
    pl: 'Omida Splash Brothers',
    en: 'Omida Bulldogs',
  },
  desc: {
    pl: `Łączymy wodę i powietrze w transporcie oraz sporcie! Poznaj naszą drużynę koszykarską Omida Splash Brothers.`,
    en: `We combine water and air with transport and sport! Meet our basketball team Omida Bulldogs.`,
  },
  keywords: [
    'omida',
    'sea and air',
    'sea & air',
    'wspieramy',
    'windsurfing',
    'ola wasiewicz',
    'aleksandra wasiewicz',
  ],
}

export const intro = {
  title: {
    pl: 'Omida Splash Brothers',
    en: 'Omida Splash Brothers',
  },
  desc: {
    pl: `Łączymy wodę i powietrze w transporcie oraz sporcie! Poznaj naszą drużynę koszykarską Omida Splash Brothers.`,
    en: `We combine water and air with transport and sport! Meet our basketball team Omida Bulldogs.`,
  },
}

export const main = {
  title: {
    pl: 'Omida Sea And Air S.A.<br/> wspiera koszykówkę!',
    en: 'Omida Sea And Air S.A.<br/> supports basketball!',
  },
  body: {
    pl: `
    <p>
    Sport, tak jak transport, jest dla nas bardzo istotny. Dobrze, kiedy współgra ze zdrowym trybem życia oraz pomaga w rozwijaniu pasji naszego zespołu. <strong>Wspieramy inicjatywy sportowe</strong>, które łączą pracowników w firmie oraz poza nią. Stawiamy na zdrowy tryb życia, relacje międzyludzkie, a wiedzę z logistyki wykorzystujemy m.in. w grach zespołowych. 😉
    </p>
    <p>
    <strong>Nie musisz pracować z nami, by z nami biegać na boisku i brać udział w rozgrywkach!</strong> Uwielbiamy sportową rywalizację, kibicowanie, dlatego wykorzystujemy naszą możliwość łączenia wody i nieba (Sea&Air). Od kilku lat mamy własną drużynę koszykarską <strong>Omida Basket Team</strong>, która pochodzi z Krakowa i wraca w tym sezonie w nowej odsłonie i pod <strong>nową nazwą "Omida Splash Brothers"</strong>. Na swoim koncie mają już szereg dobrze rozegranych meczów, które odbywają się między zaprzyjaźnionymi firmami.
    </p>
    <p>
    Zapraszamy do kibicowania, przybicia piątki z drużyną, czy wsparcia online! Chłopakom będzie bardzo miło! Dołącz do drużyny koszykarskiej Omida Sea And Air S.A. lub weź czynny udział w kibicowaniu!
    </p>
    <p>
    Więcej na ten temat znajdziecie na <a href="https://www.knba.krakow.pl/" target="_blank" rel="noreferrer noopener">stronie Krakowskiego Nurtu Basketu Amatorskiego</a>
    </p>
    `,
    en: `
    <p>
    Sport, just like transport, is very important to us. It's good when it harmonizes with a healthy lifestyle and helps in developing the passion of our team. <strong>We support sports initiatives</strong> that connect employees in the company and outside of it. We focus on a healthy lifestyle, interpersonal relationships, and we use logistics knowledge, among others in team games. 😉
    </p>
    <p>
    <strong>You don't have to work with us to run with us on the pitch and take part in the competition!</strong> We love sports competition, cheering, so we use our ability to combine water and sky (Sea & Air). For several years we have had our own basketball team <strong>Omida Basket Team</strong>, which comes from Cracow and returns this season in a new version and under the <strong>new name "Omida Bulldogs"</strong>. They have already played a number of well-played matches that take place between friendly companies.
    </p>
    <p>
    We invite you to cheer, high five with the team, or online support! The boys will be very pleased! Join the Omida Sea And Air S.A. basketball team. or take an active part in cheering!
    </p>
    <p>
     You can find more about info on <a href="https://www.knba.krakow.pl/" target="_blank" rel="noreferrer noopener"> Krakowski Nurt Basketu Amatorskiego website
     </a>
     </p>
    `,
  },
}

// export const about = {
//   title: {
//     pl: 'Poznaj Wicemistrzynię<br/>Omida Sea And Air S.A.',
//     en: 'Meet the runner-up of<br/>Omida Sea And Air S.A.',
//   },
//   desc: {
//     pl: 'Aleksandra Wasiewicz od najmłodszych lat jest związana z wodą. Do jej głównych osiągnięć należy zdobycie srebra na Mistrzostwach Świata Formula Foil w Pucku, 2 miejsca na mistrzostwach Europy, 4 miejsca na Mistrzostwach Świata.',
//     en: 'Aleksandra Wasiewicz has been associated with water from an early age. Her main achievements include winning silver at the Formula Foil World Championships in Puck, 2 places at the European Championships, 4 places at the World Championships.',
//   },
//   subtitle: {
//     pl: 'Sea & Air Wspieramy',
//     en: 'Sea & Air Supporting',
//   },
//   sections: [
//     {
//       title: {
//         pl: 'Aleksandra Wasiewicz',
//         en: 'Aleksandra Wasiewicz',
//       },
//       desc: {
//         pl: '<em>"Windsurfing jest moją pasją, zaczęło się od tego, jak mama w wieku 8 lat zapisała mnie do Sopockiego Klubu Żeglarskiego, żebym spróbowała czegoś nowego. Windsurfing kocham za kontakt z naturą, za wolność, którą odczuwam na wodzie i rywalizację"</em>',
//         en: '<em>"Windsurfing is my passion, it started when my mother, at the age of 8, enrolled me in the Sopot Sailing Club so that I would try something new. I love windsurfing for contact with nature, for the freedom I feel on the water and for competition"</em>',
//       },
//       social: {
//         pl: 'Zerknij na social media Oli 😎',
//         en: `Check out Ola's social media`,
//       },
//     },
//   ],
// }

export const blog = {
  title: {
    pl: 'Publikacje o Omida Splash Brothers',
    en: 'Publications about Omida Splash Brothers',
  },
  desc: {
    pl: 'Poniżej znajdziesz najnowsze artykuły związane z naszym basket team - śledź razem z nami sportowe osiągnięcia Omida Splash Brothers.',
    en: "Below you will find the latest articles related to our basket team - follow Omida Splash Brothers' sports achievements with us.",
  },
}

export const achievements = {
  title: {
    pl: '',
    en: '',
  },

  events: [
    {
      title: {
        pl: '',
        en: '',
      },
      desc: {
        pl: '',
        en: '',
      },
    },
    {
      title: {
        pl: '',
        en: '',
      },
      desc: {
        pl: '',
        en: '',
      },
    },
    {
      title: {
        pl: '',
        en: '',
      },
      desc: {
        pl: '',
        en: '',
      },
    },
    {
      title: {
        pl: '',
        en: '',
      },
      desc: {
        pl: '',
        en: '',
      },
    },
  ],
}

export const upcoming = {
  title: {
    pl: 'Najbliższe mecze',
    en: 'Upcoming games',
  },
  events: [
    {
      title: {
        pl: '24.04.2022',
        en: '24.04.2022',
      },
      desc: {
        pl: 'Kraków, AGH, Piastowska 26A',
        en: 'Cracow, AGH, Piastowska 26A',
      },
    },
    {
      title: {
        pl: '15.05.2022',
        en: '15.05.2022',
      },
      desc: {
        pl: 'Kraków, AGH, Piastowska 26A',
        en: 'Cracow, AGH, Piastowska 26A',
      },
    },
    {
      title: {
        pl: '22.05.2022',
        en: '22.05.2022',
      },
      desc: {
        pl: 'Kraków, AGH, Piastowska 26A',
        en: 'Cracow, AGH, Piastowska 26A',
      },
    },
    {
      title: {
        pl: '29.05.2022',
        en: '29.05.2022',
      },
      desc: {
        pl: 'Kraków, AGH, Piastowska 26A',
        en: 'Cracow, AGH, Piastowska 26A',
      },
    },
    {
      title: {
        pl: '05.06.2022',
        en: '05.06.2022',
      },
      desc: {
        pl: 'Kraków, AGH, Piastowska 26A',
        en: 'Cracow, AGH, Piastowska 26A',
      },
    },
  ],
}
