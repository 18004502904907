import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Layout from '../../../components/Layout'
import Seo from '../../../components/Seo'
import {
  seo,
  intro,
  main,
  // about,
  blog,
  upcoming,
  // achievements,
} from '../../../content/omida-bulldogs'
import { useLangContext } from '../../../context/lang.context'
import Intro from '../../../components/Intro'
import Main from '../../../components/Main'
// import Video from '../../../components/Video'
// import ImageSection from '../../../components/ImageSection'
import { sBlogs } from '../../../sections/index/BlogSection'
import BlogTile from '../../../components/BlogTile'
// import { colors, sDesc } from '../../../style'
// import YouTubeIcon from '../../../assets/icons/youtubeIcon'
// import InstagramIcon from '../../../assets/icons/instagramIcon'
// import FacebookIcon from '../../../assets/icons/facebookIcon'
import Events from '../../../components/Events'

// const video = 'https://www.youtube.com/embed/cWQBf9tK7Rk'

// const videos = [
//   'https://www.youtube.com/embed/bw1eio2k_Pc',
//   'https://www.youtube.com/embed/8hpzS8kNXos',
// ]

const OmidaBulldogs = () => {
  const { lang } = useLangContext()

  const query = useStaticQuery(graphql`
    {
      image: file(
        absolutePath: { regex: "/images/intros/omida-bulldogs.jpeg/" }
      ) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            layout: FIXED
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
          )
        }
      }
      imageAbout: file(
        absolutePath: { regex: "/images/supporting/wasiewicz/wasiewicz.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
            aspectRatio: 1
          )
        }
      }
      blogs: allContentfulBlog(
        filter: {
          blog_categories: { elemMatch: { blog_category: { eq: "Bulldogs" } } }
        }
      ) {
        nodes {
          blog_slug
          lang {
            slug
          }
          blog_categories {
            blog_category
          }
          title
          description
          image {
            gatsbyImageData(
              layout: CONSTRAINED
              quality: 100
              placeholder: DOMINANT_COLOR
              formats: [JPG]
              aspectRatio: 1.77
            )
          }
        }
      }
      # image1: file(
      #   absolutePath: { regex: "/images/supporting/bulldogs/image1.jpeg/" }
      # ) {
      #   childImageSharp {
      #     gatsbyImageData(
      #       layout: CONSTRAINED
      #       quality: 100
      #       placeholder: BLURRED
      #       formats: [JPG]
      #       aspectRatio: 1
      #     )
      #   }
      # }
      # image2: file(
      #   absolutePath: { regex: "/images/supporting/bulldogs/image2.jpeg/" }
      # ) {
      #   childImageSharp {
      #     gatsbyImageData(
      #       layout: CONSTRAINED
      #       quality: 100
      #       placeholder: BLURRED
      #       formats: [JPG]
      #       aspectRatio: 1
      #     )
      #   }
      # }
      # image3: file(
      #   absolutePath: { regex: "/images/supporting/bulldogs/image3.jpeg/" }
      # ) {
      #   childImageSharp {
      #     gatsbyImageData(
      #       layout: CONSTRAINED
      #       quality: 100
      #       placeholder: BLURRED
      #       formats: [JPG]
      #       aspectRatio: 1
      #     )
      #   }
      # }
      # image4: file(
      #   absolutePath: { regex: "/images/supporting/bulldogs/image4.jpeg/" }
      # ) {
      #   childImageSharp {
      #     gatsbyImageData(
      #       layout: CONSTRAINED
      #       quality: 100
      #       placeholder: BLURRED
      #       formats: [JPG]
      #       aspectRatio: 1
      #     )
      #   }
      # }
    }
  `)

  const blogs = query.blogs.nodes.map(
    ({ title, description, image, blog_slug }) => ({
      title,
      desc: description,
      slug: blog_slug,
      image,
    })
  )

  const button = {
    text: {
      pl: 'Przeglądaj',
      en: 'Explore',
    },
    action: 'SCROLL',
  }

  // const eventsAchivements = achievements.events
  //   .map((e, id) => ({ ...e, image: query[`image${id + 1}`]?.childImageSharp }))
  //   .reverse()

  // console.log(query)

  const eventsUpcoming = upcoming.events

  return (
    <Layout>
      <Seo
        title={seo.title[lang]}
        desc={seo.desc[lang]}
        url={seo.url}
        keywords={seo.keywords}
      />
      <Intro
        data={{ ...intro, button }}
        image={query.image.childImageSharp}
        position='center'
      />
      <Main h={2} s={1} title={main.title[lang]} body={main.body[lang]}>
        {/* <div css={{ paddingBottom: 80 }} />
        <Video video={video} videos={videos} /> */}
      </Main>
      {/* <Main h={1} title={about.title[lang]} desc={about.desc[lang]}>
        {about.sections?.map(({ title, desc, social }, id) => (
          <ImageSection
            // html
            noeffect
            h={2}
            s={2}
            key={id}
            title={title[lang]}
            desc={desc?.[lang]}
            image={query.imageAbout.childImageSharp}>
            <p css={sSocialText}>{social[lang]}</p>
            <div css={sSocialIcons}>
              <a
                href='https://www.youtube.com/channel/UCVN-h5l2ByxEjGFxqUbCV4A'
                target='_blank'
                rel='noreferrer noopener'>
                <YouTubeIcon />
              </a>
              <a
                href='https://www.facebook.com/aleksandrawasiewiczpol122'
                target='_blank'
                rel='noreferrer noopener'>
                <FacebookIcon />
              </a>
              <a
                href='https://www.instagram.com/a.wasiewicz/'
                target='_blank'
                rel='noreferrer noopener'>
                <InstagramIcon />
              </a>
            </div>
          </ImageSection>
        ))}
      </Main> */}
      <Main
        h={2}
        s={1}
        title={blog.title[lang]}
        desc={blog.desc[lang]}
        subtitle={'Sea & Air Blog'}
      >
        <div css={sBlogs}>
          {blogs.map((blog, id) => (
            <BlogTile key={id} data={blog} />
          ))}
        </div>
      </Main>
      {/* <Main h={3} s={2} title={achievements.title[lang]}>
        <Events data={eventsAchivements} />
      </Main> */}
      {/* <Main h={3} s={2} title={upcoming.title[lang]}>
        <Events data={eventsUpcoming} />
      </Main> */}
    </Layout>
  )
}

// const sSocialText = {
//   margin: '2rem 0 2rem',
//   ...sDesc,
// }

// const sSocialIcons = {
//   display: 'flex',
//   color: colors.brandDark,

//   svg: {
//     width: '2rem',
//     height: '2rem',
//     marginRight: '1rem',
//     transition: 'color 0.2s ease-out',
//     '&:hover': {
//       color: colors.brandDarker,
//     },
//   },
// }

export default OmidaBulldogs
